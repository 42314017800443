<template>
  <v-card light>
    <v-container fluid>
      <v-row dense class="pr-6" v-if="item">
        <v-col cols="12" class="d-flex justify-space-between pa-0">
          <v-btn
            class="body-1 black--text"
            :to="`/order/details/${item.orderId}`"
            text
          >
            Order Id {{item.orderId}}
          </v-btn>
          <div class="money black--text">{{item.itemAmount | usDollars}}</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card color="grey lighten-4">
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-window v-model="window" touchless>
                    <v-window-item :value="0">
                      <v-expand-transition>
                        <div class="text-center" v-if="loading">
                          <v-progress-circular indeterminate color="color3"></v-progress-circular>
                          <div>Looking up payment details...</div>
                        </div>
                      </v-expand-transition>
                      <v-expand-transition>
                        <div v-if="!loading">
                          <template v-if="item">
                            <template v-for="charge in item.paymentHistory">
                              <!-- PAYMENT -->
                                <payment-history-item
                                  :key="charge.id"
                                  :charge="charge"
                                  :canRefund="canRefund"
                                  @refund-click="selectCharge"
                                  @delete-click="onDeleteClick"
                                ></payment-history-item>
                              <!-- REFUNDS -->
                              <template v-for="refund in charge.refunds">
                                <v-row dense :key="refund.id" class="pa-3 pb-0">
                                  <v-col cols="12" class="d-flex justify-space-between pa-0">
                                    <div class="body-1 black--text">Refund <span class="xsmall grey--text">{{refund.id}}</span></div>
                                    <div class="money error--text">-{{refund.amount | usDollars}}</div>
                                  </v-col>
                                  <v-col cols="12" class="pa-0 caption grey--text">
                                    {{refund.dt | unixToLocalDt}} | By:  {{refund.user}}
                                  </v-col>
                                  <v-col cols="12" class="pa-0 grey--text xsmall">
                                    {{refund.note}}
                                  </v-col>
                                </v-row>
                              </template>
                            </template>
                            <!-- TOTAL ROW -->
                            <v-row dense v-if="item.paymentHistory.length" class="pa-3">
                              <v-col cols="12" class="px-0">
                                <v-divider></v-divider>
                              </v-col>
                              <v-col cols="12" class="d-flex justify-space-between pa-0">
                                <div class="body-1 black--text">Total Paid</div>
                                <div class="money black--text">{{total | usDollars}}</div>
                              </v-col>
                            </v-row>

                            <template v-if="!item.paymentHistory.length">
                              <div class="py-4 body-1" v-if="item.requirePayment">
                                This registration is pending payment and can be paid here:
                                  <v-btn color="color3" text class="pa-0 ma-0" :to="`/order/details/${credentials.id}`">
                                    {{orderUrl}}
                                  </v-btn>
                              </div>
                              <div class="py-4 body-1" v-else>
                                It appears there was no payment processed.
                                <v-btn
                                  color="color3 color3Text--text"
                                  v-if="adminUser"
                                  @click.stop="window = 3"
                                >
                                  Make Payable
                                </v-btn>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            <v-row dense>
                              <v-col class="text-center" >
                                <h3>It appears the payment was not processed through Volleyball Life.</h3>
                                <v-btn
                                  color="color3 color3Text--text"
                                  v-if="adminUser"
                                  @click.stop="window = 3"
                                >
                                  Make Payable
                                </v-btn>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                      </v-expand-transition>
                    </v-window-item>
                    <v-window-item :value="1">
                      <issue-refund
                        :charge="selectedCharge"
                        :bagItemId="bagItemId"
                        @refund-complete="init"
                        @busy-change="onBusyChange"
                        :embedded="embedded"
                      ></issue-refund>
                    </v-window-item>
                    <v-window-item :value="2">
                      <v-card color="grey lighten-4 my-3">
                        <v-container fluid>
                          <v-row dense>
                            <v-col cols="12" class="text-center">
                              <h3>Add External Payment*</h3>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="Amount"
                                type="number"
                                color="color3"
                                :disabled="busy"
                                v-model="xAmount"
                                prepend-icon="fas fa-dollar-sign"
                                @blur="formatCurrency"
                              ></v-text-field>
                              <v-combobox
                                v-model="xSource"
                                :items="['Cash', 'Cash App', 'Venmo', 'PayPal', 'Zelle']"
                                label="Source"
                                color="color3"
                                :disabled="busy"
                                item-color="color3"
                              ></v-combobox>
                              <v-textarea
                                label="Notes"
                                v-model="xNotes"
                                color="color3"
                                :disabled="busy"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" class="text-right caption">
                              *For you record keeping purposes only
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-window-item>
                    <v-window-item :value="3">
                        <v-container fluid>
                          <v-row dense>
                            <v-col cols="12" class="text-center">
                              <h3>Make this registration payable?</h3>
                            </v-col>
                            <v-col cols="12" class="text-center">
                              <v-btn color="success" class="mr-2" @click.stop="makePay" :loading="busy">Yes</v-btn>
                              <v-btn color="error" @click.stop="window=0" :disabled="busy">No</v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row justify="end">
            <v-btn
              color="color3"
              text x-small
              @click.stop="window = 2"
              v-if="adminUser && window !== 2"
            >
              Add External Payment
            </v-btn>
        </v-row>
      </v-expand-transition>
      <v-row class="px-3">
        <v-fab-transition>
          <v-btn
            color="color3Text color3--text"
            small fab
            v-if="window > 0"
            @click="window = 0"
            :disabled="busy"
          >
            <v-icon>fas fa-caret-left</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          small fab
          :loading="busy"
          @click.stop="onAddClick"
          :disabled="!xValid"
          v-if="window === 2"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-row>
      <v-row class="pt-3" v-if="!embedded">
          <v-spacer></v-spacer>
          <v-btn
            color="color3"
            x-small text class="xsmall"
            :loading="loading"
            @click.stop="getBagItem(true)"
          >
            <v-icon>fas fa-redo-alt</v-icon>
          </v-btn>
      </v-row>

    </v-container>
  </v-card>

</template>

<script>
import sum from 'lodash.sum'
import { mapGetters } from 'vuex'
const IssueRefund = () => import('@/components/Cart/IssueRefund.vue')
const PaymentHistoryItem = () => import('@/components/Cart/PaymentHistoryItem.vue')

export default {
  props: ['bagItemId', 'credentials', 'active', 'embedded', 'teamId', 'isAdmin'],
  data () {
    return {
      loading: null,
      item: null,
      window: 0,
      selectedCharge: null,
      busy: false,
      dialog: false,
      xAmount: null,
      xSource: null,
      xNotes: null,
      selectedId: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    orderUrl () {
      return `${window.location.origin}/order/details/${this.credentials.id}`
    },
    canRefund () {
      return !!(this.item && this.user && (this.user.vbl || this.user.isOrganizationAdmin(this.item.organizationId)))
    },
    adminUser () {
      return !!(this.user && (this.user.vbl || this.isAdmin || this.user.isOrganizationAdmin(this.item.organizationId)))
    },
    total () {
      return this.item && this.item.paymentHistory ? sum(this.item.paymentHistory.map(m => this.itemTotal(m))) : 0
    },
    xValid () {
      return !!(this.xAmount && this.xSource)
    },
    xDto () {
      return {
        shoppingBagItemId: this.bagItemId || 0,
        amount: this.xAmount,
        source: this.xSource,
        notes: this.xNotes,
        teamId: this.teamId
      }
    }
  },
  methods: {
    getBagItem (force) {
      if (!this.bagItemId) return
      if (this.item && this.item.id === this.bagItemId && !force) return
      this.loading = true
      this.$VBL.cart.getBagByItem(this.bagItemId, this.credentials)
        .then(r => {
          this.init(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    itemTotal (charge) {
      if (charge.amount < 0) return 0
      return charge.amount - (charge.refunds ? sum(charge.refunds.map((r) => r.amount)) : 0)
    },
    selectCharge (charge) {
      this.selectedCharge = charge
      this.window = 1
    },
    init (item) {
      this.reset()
      this.item = item
      this.window = 0
    },
    onBusyChange (val) {
      this.busy = val
      this.$emit('busy-change', val)
    },
    formatCurrency () {
      const fee = parseFloat(this.xAmount)
      this.xAmount = isNaN(fee) ? null : fee.toFixed(2)
    },
    reset () {
      this.selectedCharge = null
      this.xAmount = null
      this.xSource = null
      this.xNotes = null
    },
    onDeleteClick (id) {
      this.postExternalPayment({
        id: id,
        shoppingBagItemId: this.bagItemId,
        deleted: true
      })
    },
    onAddClick () {
      this.postExternalPayment(this.xDto)
    },
    postExternalPayment (dto) {
      this.busy = true
      this.$VBL.cart.postExternalPayment(dto || this.xDto)
        .then(r => {
          this.$emit('update')
          this.init(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => { this.busy = false })
    },
    makePay () {
      this.busy = true
      this.$VBL.cart.makePayable({
        shoppingBagItemId: this.bagItemId || 0,
        teamId: this.teamId
      })
        .then(r => {
          this.$emit('update')
          this.init(r.data)
        })
        .catch(e => console.log(e))
        .finally(() => { this.busy = false })
    }
  },
  watch: {
    bagItemId: 'getBagItem',
    active: function (val) {
      this.getBagItem(true)
    },
    window: function (val) {
      this.$emit('window-change', val)
      val === 0 && this.reset()
    }
  },
  components: {
    IssueRefund,
    PaymentHistoryItem
  },
  mounted () {
    this.getBagItem()
  }
}
</script>

<style scoped>
.money {
  font-size: 1rem !important;
}
</style>
